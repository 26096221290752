import {Stat} from "../Stat";
import {useEffect, useState} from "react";
import {inventoryReportSchema, invReportSummary} from "../../../data/chartSchemas";
import {ColChart, LineChart} from "../../charts/LineChart";
import {groupByCol} from "../../../utils/dataTransforms";

const prepareSelectedInvStats = (products, stats) => {
    let result = inventoryReportSchema
    const qty = [...new Set(products.map(x => x._sku))].length
    const inStockQty = [...new Set(products.filter(x => x._qty > 0).map(x => x._sku))].length
    const revenue = products.reduce((sum, i) => sum + i._revenue, 0).toFixed(2)
    const netProfit = products.reduce((sum, i) => sum + i._net_profit, 0).toFixed(2)
    const unitsSold = products.reduce((sum, i) => sum + i._units_sold, 0)
    result.base.brands.value = [...new Set(products.map(x => x._brand_name))].length
    result.base.categories.value = [...new Set(products.map(x => x._master_category_name))].length
    result.base.subcategories.value = [...new Set(products.map(x => x._category_name))].length
    result.base.sku.value = qty
    result.base.skuInStock.value = inStockQty
    result.base.unitsInStock.value = products.reduce((sum, i) => sum + i._qty, 0)
    result.consumption.avgBurnRate.value = (products.reduce((sum, i) => sum + i._burn_rate, 0) / qty).toFixed(2)
    result.consumption.avgBurnRatePeriod.value = (products.reduce((sum, i) => sum + i._burn_rate_period, 0) / qty).toFixed(2)
    result.consumption.maxDaysLeft.value = Math.max(...products.map(x => x._days_left)).toFixed(2)
    result.consumption.minDaysLeft.value = Math.min(...products.map(x => x._days_left).filter(x => x > 0)).toFixed(2)
    result.consumption.projectedSales90d.value = (products.filter(x => x._qty > 0).reduce((sum, i) => sum + i._burn_rate, 0) / inStockQty).toFixed(2)
    result.consumption.projectedSales30d.value = (products.filter(x => x._qty > 0).reduce((sum, i) => sum + i._burn_rate_period, 0) / inStockQty).toFixed(2)
    result.sales.revenue.value = revenue
    result.sales.netProfit.value = netProfit
    result.sales.unitsSold.value = unitsSold
    result.sales.pctRevenue.value = stats ? (revenue / stats[0]['_total_revenue'] * 100).toFixed(2) : 0
    result.sales.pctProfit.value = stats ? (netProfit / stats[0]['_total_profit'] * 100).toFixed(2) : 0
    result.sales.pctSales.value =  stats ? (unitsSold / stats[0]._total_sales * 100).toFixed(2) : 0
    result.sales.pctMargin.value = (netProfit / revenue * 100).toFixed(2)
    return result
}


export const Summary = ({activeTab, baseStats, selectedProducts, graphData}) => {
    // Calculates stats based on selectedProducts and additional data.
    // Pipes graph data as is
    const [stats, setStats] = useState(inventoryReportSchema)
    const [graph, setGraph] = useState(invReportSummary)

    useEffect(() => {
        if (!activeTab || !graphData) return  // load only if your tab is active
        console.log('call Summary effect')

        let unitsSold = groupByCol(graphData, '_date', '_units_sold')
        let marginPct = groupByCol(graphData, '_date', '_avg_margin_pct', 'avg')
        unitsSold.series[0]['showInLegend'] = false
        marginPct.series[0]['showInLegend'] = false

        const newStats = prepareSelectedInvStats(selectedProducts, baseStats)
        setStats(newStats)
        setGraph({['unitsSold']: unitsSold, ['marginPct']: marginPct})

    }, [graphData, activeTab])

    if (!activeTab) return null
    console.log('call Summary')
    return (
        <div>
                <div className="grid stats-vertical stats sm:stats-horizontal shadow">
                    {Object.values(stats.base).map(v => <Stat {...v}/>)}
                </div>
            <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 gap-4 my-4">
                <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 stats stats-vertical shadow">
                    {Object.values(stats.consumption).map(v => <Stat {...v}/>)}
                </div>
                <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 stats stats-vertical shadow">
                    {Object.values(stats.sales).map(v => <Stat {...v}/>)}
                </div>
            </div>
            <div className="grid lg:grid-cols-2 md:grid-cols-1 gap-4">
                <ColChart title={{text: 'Units Sold'}}
                           yAxisName='qty'
                           data={graph.unitsSold}/>
                <LineChart title={{text: 'Margin %'}}
                           yAxisName='pct'
                           data={graph.marginPct}/>
            </div>
        </div>
    )
}