import {filterDefault, PageNames, Paragraphs} from "../data/options";
import {Filter} from "./base/Filter";
import {TenantSelector} from "./base/Selector";
import React, {useEffect, useState} from "react";
import apiCall from "./base/call";
import {InventoryReports} from "./dashboards/inventoryReports/InventoryReports"
import BrandSales from "./dashboards/BrandSales";
import {InventoryOverview} from "./dashboards/InventoryOverview";
import {Table} from "./charts/Table";
import {colNamingLambda, colVisibilityFilter} from "../utils/tableLambdas";


export const InventoryPage = ({auth, setAlert}) => {
    const [filters, setFilters] = useState(filterDefault)
    const [products, setProducts] = useState([])
    const [rowSelection, setRowSelection] = useState({})
    const [selectedProducts, setSelectedProducts] = useState([])

    useEffect(() => {
        const selection  = Object.keys(rowSelection).map(idx => products[idx])
        setSelectedProducts(selection)
    }, [rowSelection])

    const handleFilterClick = (e) => {
        e.preventDefault()
        let filter = !filters.filter
        setFilters({...filters, filter})
        apiCall(setAlert, 'rpc/inv_all_products', filters, auth).then(resp => {
            if (resp) {
                let dt = resp.data
                setProducts(dt)
            }
        })
    }

    console.log('rendering INV PAGE')
    return (
        <div className="max-w-[100vw] pb-16 xl:pr-2 m-4">
            <div className="flex flex-row items-center justify-between mb-4">
                <p className='font-sans pl-4 mt-2 inline-block text-2xl sm:text-3xl font-extrabold text-slate-900 tracking-tight dark:text-slate-200'>
                    {PageNames.INVENTORY.name}
                </p>
                <Filter filters={filters} setFilters={setFilters}/>
            </div>
            <div className="flex flex-row items-center justify-between ">
                <TenantSelector state={filters} setState={setFilters} setAlert={setAlert} auth={auth} name='tenant_id'/>
                <button className="btn btn-sm w-full max-w-xs" onClick={handleFilterClick}>Filter</button>
            </div>
            <InventoryOverview setAlert={setAlert} filters={filters} auth={auth} optionalName={Paragraphs.OVERVIEW}/>
            <div className="m-2 mt-8">
                <BrandSales setAlert={setAlert} filters={filters} activeTab={true} auth={auth}/>
                <Table data={products} filtering={true} rowSelection={rowSelection} setRowSelection={setRowSelection}
                       colVisibilityFilter={colVisibilityFilter} colNamingLambda={colNamingLambda}/>
            </div>
            <InventoryReports setAlert={setAlert} filters={filters} auth={auth} selectedProducts={selectedProducts}/>
        </div>
    )
}


