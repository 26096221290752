import {ColChart, LineChart} from "../../charts/LineChart";
import React, {useEffect, useState} from "react";
import {discountBatchDetails, invReportSummary} from "../../../data/chartSchemas";
import {groupAggregateAllProducts, groupByaggregateBy, preparePieSum} from "../../../utils/dataTransforms";
import {Table} from "../../charts/Table";
import {colNamingLambda, colVisibilityFilter} from "../../../utils/tableLambdas";
import PieChart from "../../charts/PieChart";

export const GenericReport = ({activeTab, mutation, selectedProducts, graphData, extraData, children }) => {
    //Tabs Categories, Subcategories, Brands, Products
    // Groups by selected products by specific case
    // Pipes graph data as is
    const [graph, setGraph] = useState(invReportSummary)
    const [rowSelection, setRowSelection] = useState({})
    const [products, setProducts] = useState([])
    const [discToBatch, setDiscToBatch] = useState(discountBatchDetails)

    const passAsIs = ['_discount_name', '_product_name', '_batch_name']

    useEffect(() => {
        if (!activeTab || !graphData) return  // load only if your tab is active
        console.log('call generic effect')

        const aggregatedProducts = passAsIs.includes(mutation) ? selectedProducts : groupAggregateAllProducts(selectedProducts, mutation)
        setProducts(aggregatedProducts)

        const unitsSold = groupByaggregateBy(graphData, '_date', mutation, '_units_sold')
        const marginPct = groupByaggregateBy(graphData, '_date', mutation, '_avg_margin_pct', 'avg')

        const selection  = Object.keys(rowSelection).map(idx => products[idx][mutation])
        if (selection.length > 0) {
            unitsSold.series = unitsSold.series.filter(x => selection.includes(x.name))
            marginPct.series = marginPct.series.filter(x => selection.includes(x.name))
        }
        setGraph({['unitsSold']: unitsSold, ['marginPct']: marginPct})
    },[graphData, activeTab, rowSelection, selectedProducts])


    useEffect(() => {
        if (!activeTab || !extraData || extraData?.length < 0) return
        const batchDistribution = groupByaggregateBy(extraData, '_batch_name', '_discount_name', '_units_sold')
        const discDistribution = preparePieSum(batchDistribution.series, 'Units Sold')
        setDiscToBatch({['discDistribution']: discDistribution, ['batchDistribution']: batchDistribution})
    }, [extraData, activeTab])

    const filterSkuCol = (sampleRow) => {
        if (!sampleRow || sampleRow.length === 0) return
        let excludeCols = sampleRow.filter(x => ['_sku'].indexOf(x) > -1)
        excludeCols = excludeCols.reduce((a, v) => ({ ...a, [v]: false}), {})
        return excludeCols
    }

    if (!activeTab) return null
    console.log('call Generic Report with mutation', mutation)
    return (
        <div className="">
            <div className="grid lg:grid-cols-2 md:grid-cols-1 gap-4">
                <ColChart title={{text: 'Units Sold'}}
                           yAxisName='USD'
                           data={graph.unitsSold}/>
                <LineChart title={{text: 'Margin %'}}
                           yAxisName='pct'
                           data={graph.marginPct}/>
            </div>
            <div className="">
                {children}
                <Table data={products}
                       filtering={false}
                       rowSelection={rowSelection}
                       setRowSelection={setRowSelection}
                       colVisibilityFilter={mutation === '_product_name' ? colVisibilityFilter : filterSkuCol}
                       colNamingLambda={colNamingLambda}/>
            </div>
            {(extraData && extraData?.length > 0) ?
            <div className="">
                <p className='font-sans pl-4 mt-8 inline-block text-2xl sm:text-3xl font-extrabold text-slate-900 tracking-tight dark:text-slate-200'>
                    Discounts Distribution by Product/Batch
                </p>
                <div className="grid lg:grid-cols-2 md:grid-cols-1 gap-4">
                    <PieChart title={{text: 'Units Sold by Discount'}}
                              data={discToBatch.discDistribution}/>
                    <ColChart title={{text: 'Units Sold by Batch'}}
                              yAxisName='USD'
                              data={discToBatch.batchDistribution}/>
                </div>
                <Table data={extraData}
                       filtering={false}
                       colVisibilityFilter={colVisibilityFilter}
                       colNamingLambda={colNamingLambda}/>
            </div>
            : null}
        </div>
    )
}