export const    mapData = (dt, xAxis, colMap) => {
    const cols = Object.keys(colMap)
    const ser = cols.map(colName => ( {name: colName, data: dt.map( x => x[colMap[colName]] )} ))
    const xAixCats = dt.map(x => x[xAxis])
    // const dataPointsCount = ser.reduce((sum, x) => sum + x.data.length, 0)
    return {
        xAxis: [{
            type: 'datetime',
            categories: [...new Set(xAixCats)]
        }],
        series: ser
    }
}

const emptyObject = (obj, excludeCol) => {
    // {a:10, b:20, c: 'foo'} => {a:0, b:0} while excluding property 'c'
    let objKeys = Object.keys(obj).filter((x) => x !==  excludeCol)
    objKeys.push('count')
    let cleanObject = Object.fromEntries(objKeys.map(k => [k, 0]))
    return cleanObject
}

export const getAvgGroupedByDimension = (data, dimension) => {
    // SELECT avg(c1), avg(c2), avg(c3) FROM data GROUP BY dimension
    let result = {}
    for (const row of data) {
        let period = row[dimension]
        if(!result[period]) result[period] = emptyObject(data[0], dimension)

        let props = Object.keys(data[0]).filter((x) => x !==  dimension)
        result[period].count += 1
        for (const prop of props) {
            result[period][prop] = (result[period][prop] + row[prop] ) / result[period].count
        }
    }
    return result
}

const getMaxKeyFrom = obj => {
    return Object.keys(obj).reduce(function (a, b) { return a > b ? a : b; })
}

const getMinKeyFrom = obj => {
    return Object.keys(obj).reduce(function (a, b) { return a < b ? a : b; });
}

export const extractStatsArrangedByPeriod = (storesAvg, dimension) => {
    // extract stats from the obj: {'2023-01-01: {sales:100},  '2023-02-01': {sales:200} }
    let currPeriodValue = storesAvg[getMaxKeyFrom(storesAvg)][dimension]
    let prevPeriodValue = storesAvg[getMinKeyFrom(storesAvg)][dimension]
    let trendChangePct = (currPeriodValue - prevPeriodValue) / prevPeriodValue * 100

    return {
        main: currPeriodValue.toFixed(2),
        trend: {
            points: trendChangePct.toFixed(2),
            direction: trendChangePct > 0
        }
    }
}

export const aggregateFunction = (series, aggAction = null) => {
    let aggregatedSeries = []
    switch(true) {
        case aggAction === 'avg':
            const avg = (sum, length) => {return isNaN(sum/length) ? 0 : sum/length }
            aggregatedSeries = Object.values(series).map(x => avg(x.runningSum, x.length))
            break
        default:
            aggregatedSeries = Object.values(series).map(x => x.runningSum)
    }
    return aggregatedSeries
}

export const groupByCol = (dt, xAxis, groupBy, aggAction = null) => {
    // Applies to data format:
    // [{date: '2024-02-01', brand: 'A', val: 1}, {date: '2024-02-01', brand: 'A', val: 3},
    // {date: '2024-02-01', brand: 'B', val: 0}, {date: '2024-02-01', brand: 'B', val: 4} ]
    // Follows the current transformation steps:
    // {2024-02-01: val_sum, 2024-02-01: val_sum} => [{name: colName, data: [1,2,3]}]
    const xAixCats = [...new Set( dt.map(x => x[xAxis]) )]
    let seriesMap = Object.fromEntries( xAixCats.map(x => [x, {runningSum: 0, length: 0}]))
    for (let row of dt) {
        if (row[groupBy] === null) continue

        let currDate = row[xAxis]
        let value = row[groupBy]
        seriesMap[currDate]['length'] += 1
        seriesMap[currDate]['runningSum'] += (value < -100 ? 0 : value)  // TODO: apply aggAction sum/count/avg/median
    }

    const seriesArr = [{name: groupBy, data: aggregateFunction(seriesMap, aggAction)}]
    return {
        xAxis: [{categories: xAixCats}],
        series: seriesArr,
        count: xAixCats.length * seriesArr.length
    }
}

export const groupByaggregateBy = (dt, xAxis, groupBy, aggregateBy, aggAction = null) => {
    // Applies to data format:
    // [{date: '2024-02-01', brand: 'A', val: 1}, {date: '2024-02-01', brand: 'A', val: 3},
    // {date: '2024-02-01', brand: 'B', val: 0}, {date: '2024-02-01', brand: 'B', val: 4} ]
    // Follows the current transformation steps:
    // { brandA: {2024-02-01: val_sum}, brandB: {2024-02-01: val_sum} }
    // => {brandA: [1,2,3], brandB: [1,2,3]}
    // => [{name: brandA, data: [1,2,3]}]
    let seriesMap = {}
    const xAixCats = [...new Set( dt.map(x => x[xAxis]) )]
    for (let row of dt) {
        if (row[groupBy] === null) continue

        let currDate = row[xAxis]
        let groupName = row[groupBy] ? row[groupBy] : 'Undefined'
        let value = row[aggregateBy]
        seriesMap[groupName] ??= Object.fromEntries( xAixCats.map(x => [x, {runningSum: 0, length: 0}]) )
        seriesMap[groupName][currDate]['length'] += 1
        seriesMap[groupName][currDate]['runningSum'] += (value < -100 ? 0 : value) // TODO: apply aggAction sum/count/avg/median
    }

    const seriesArr = Object.entries(seriesMap).map( ([key, value]) => ({name: key, data: aggregateFunction(value, aggAction)}) )
    return {
        xAxis: [{categories: xAixCats}],
        series: seriesArr,
        count: xAixCats.length * seriesArr.length
    }
}

export const preparePieSum = (dataArr, dimensionName) => {
    const sum = arr => {return arr.reduce( (sum, a) => sum + a, 0 )}
    return {
        name: dimensionName,
        colorByPoint: true,
        data: dataArr.map(x => ( {name: x.name, y: Math.round(sum(x.data))} ))
    }
}

export const convertToArrMatrix = (data, dimensionNames) => {
    let series = []
    let counter = 0
    let xAxisMap = Object.assign( ...dimensionNames.map((key, index) => ( {[key]: index} )) )
    for (let row of data) {
        let rowArr = Object.keys(xAxisMap).map(x => ( [xAxisMap[x], counter, row[x]] ))
        series.push(...rowArr)
        counter++
    }
    return series
}

export const pivotMatrixByDay = (data, dimensionName, yAxisName) => {
    let series = []
    let yAxisArr = data[1].map(x => x[yAxisName])
    let hourGroupMap = Object.assign( ...yAxisArr.map((key, index) => ( {[key]: index} )) )

    for (const [dayNumber, arr] of Object.entries(data)) {
        for (let row of arr) {
            let xAxisVal = dayNumber - 1
            let yAxisVal = hourGroupMap[row._hourgroup]
            let cellVal = row[dimensionName]
            let newRow = [xAxisVal, yAxisVal, cellVal]
            series.push(newRow)
        }
    }

    return {
        xAxis: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        yAxis: yAxisArr,
        series: series
    }
}

export const groupAggregateAllProducts = (data, groupByProperty) => {
    const result = Object.values(
        data.reduce((acc, p) => {
            const groupKey = p[groupByProperty];
            const dct = acc[groupKey];

            if (dct) {
                // If the category already exists, update the aggregated data
                acc[groupKey] = {
                    ...dct,
                    _qty: dct._qty + parseInt(p._qty ? p._qty : 0),
                    _agg_cost: dct._agg_cost + parseFloat(p._total_unit_cost ? p._total_unit_cost : 0.00),
                    _agg_price: dct._agg_price + parseFloat(p._total_unit_price ? p._total_unit_price : 0.00),
                    _burn_rate: dct._burn_rate.concat([parseFloat(p._burn_rate ? p._burn_rate : 0.00)]),
                    _burn_rate_p: dct._burn_rate_p.concat([parseFloat(p._burn_rate_period ? p._burn_rate_period : 0.00)]),
                    _categories: dct._categories.add(p._master_category_name),
                    _sub_categories: dct._sub_categories.add(p._category_name),
                    _brands: dct._brands.add(p._brand_name),
                    _products: dct._products.add(p._product_name),
                    _discounts: dct._discounts.add(p._discount_name),
                    _batch: dct._batch.add(p._batch_name),
                    _sku: dct._sku.add(p._sku),
                    _thc: dct._thc.concat([parseFloat(p._avg_thc ? p._avg_thc : 0.00)]),
                    _days_left: dct._days_left + (p._days_left ? parseFloat(p._days_left) : 0.00),
                    _units_sold: dct._units_sold + p._units_sold,
                    _revenue: dct._revenue + (p._revenue ? parseFloat(p._revenue) : 0.00),
                    _margin: dct._margin + (p._net_profit ? parseFloat(p._net_profit) : 0.00),
                    _avg_margin_pct: dct._avg_margin_pct + ( (p._net_profit ? parseFloat(p._net_profit) :1.00) / (p._revenue ? parseFloat(p._revenue) : 1.00) * 100 )
                    // Add other properties to aggregate here
                };
            } else {
                // If the category doesn't exist, create a new entry
                acc[groupKey] = {
                    [groupByProperty]: groupKey,
                    _qty: parseInt(p._qty ? p._qty : 0),
                    _agg_cost: parseFloat(p._total_unit_cost ? p._total_unit_cost : 0.00),
                    _agg_price: parseFloat(p._total_unit_price ? p._total_unit_price : 0.00) ,
                    _burn_rate: [parseFloat(p._burn_rate ? p._burn_rate : 0.00)],
                    _burn_rate_p: [parseFloat(p._burn_rate_period ? p._burn_rate_period : 0.00)],
                    _categories: new Set().add(p._master_category_name),
                    _sub_categories: new Set().add(p._category_name),
                    _brands: new Set().add(p._brand_name),
                    _products: new Set().add(p._product_name),
                    _batch: new Set().add(p._batch_name),
                    _discounts: new Set().add(p._discount_name),
                    _sku: new Set().add(p._sku),
                    _thc: [parseFloat(p._avg_thc ? p._avg_thc : 0.00)],
                    _days_left: p._days_left ? parseFloat(p._days_left) : 0.00,
                    _units_sold: p._units_sold,
                    _revenue: p._revenue ? parseFloat(p._revenue) : 0.00,
                    _margin: p._net_profit ? parseFloat(p._net_profit) : 0.00,
                    _avg_margin_pct: (p._net_profit ? parseFloat(p._net_profit) : 1.00) / (p._revenue ? parseFloat(p._revenue) : 1.00)*100,
                    // _days_availible: p._days_availible,
                    _exp_date: 'expiration_date' in p ? (!! p._min_expiration_date? p._min_expiration_date.slice(0, 10): null) : null
                    // Initialize other properties as needed
                };
            }
            return acc;
        }, {})
    );

    for (let i of result) {
        i._avg_margin_pct = i._avg_margin_pct / i._products.size
        i._days_left = i._days_left / i._products.size
        i._burn_rate = (i._burn_rate.reduce((sum, i) => sum + i, 0) / i._burn_rate.length).toFixed(2)
        i._burn_rate_p = (i._burn_rate_p.reduce((sum, i) => sum + i, 0) / i._burn_rate_p.length).toFixed(2)
        i._thc = (i._thc.reduce((sum, i) => sum + i, 0) / i._thc.length).toFixed(2)
        i._products = i._products.size
    }

    return result
}
