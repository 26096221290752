import TabsContainer from "./base/Tabs";
import GrowthGraphs from "./dashboards/GrowthGraphs";
import CategorySales from "./dashboards/CategorySales";
import BrandSales from "./dashboards/BrandSales";
import PurchaseBehaviour from "./dashboards/PurchaseBehaviour";
import PeriodComparison from "./dashboards/PeriodComparison";
import {Filter, FilterNew} from "./base/Filter";
import {useEffect, useState} from "react";
import {Selector, TenantSelector} from "./base/Selector";
import Staffing from "./dashboards/Staffing";
import {filterDefault, movingAvg, PageNames} from "../data/options";

export const StorePerformancePage = ({auth, setAlert}) => {
    const [lastTab, setLastTab] = useState(0)
    const [activeTab, setActiveTab] = useState([])
    const [filters, setFilters] = useState(filterDefault)

    useEffect(() => {
        console.log('reloadingInvContainer')
        setActiveTab([lastTab])
    }, [])

    const handleFilterClick = (e) => {
        e.preventDefault()
        setActiveTab([lastTab])
        console.log('resetting active tab state to', lastTab);

        let filter = !filters.filter
        setFilters({...filters, filter})
        console.log('Filter Applied', filters)
    }

    return (
        <div className="max-w-[100vw] pb-16 xl:pr-2 m-4">
            <div className="flex flex-row items-center justify-between mb-4">
                    <p className='font-sans pl-4 mt-2 inline-block text-2xl sm:text-3xl font-extrabold text-slate-900 tracking-tight dark:text-slate-200'>
                        {PageNames.PERFORMANCE.name}
                    </p>
                <Filter filters={filters} setFilters={setFilters}>
                    <Selector options={movingAvg} state={filters} setState={setFilters} name='r_avg' labelName='Show Moving Avg Days:'/>
                </Filter>
            </div>
            <div className="flex flex-row items-center justify-between ">
                <TenantSelector state={filters} setState={setFilters} setAlert={setAlert} auth={auth} name='tenant_id'/>
                <button className="btn btn-sm w-full max-w-xs" onClick={handleFilterClick}>Filter</button>
            </div>
            <div className="m-2">
            <TabsContainer activeTab={activeTab} setLastTab={setLastTab} setActiveTab={setActiveTab}>
                <GrowthGraphs name="Growth Graphs" activeTab={activeTab.includes(0)} setAlert={setAlert} filters={filters} auth={auth}/>
                <CategorySales name="Category Sales" activeTab={activeTab.includes(1)} setAlert={setAlert} filters={filters} auth={auth}/>
                <BrandSales name="Brand Sales" activeTab={activeTab.includes(2)} setAlert={setAlert} filters={filters} auth={auth} lineCharts/>
                <PurchaseBehaviour name="Purchase Behaviour" activeTab={activeTab.includes(3)} setAlert={setAlert} filters={filters} auth={auth}/>
                <PeriodComparison name="Period Comparison" activeTab={activeTab.includes(4)} setAlert={setAlert} filters={filters} auth={auth}/>
                <Staffing name="Staffing" activeTab={activeTab.includes(5)} setAlert={setAlert} filters={filters} auth={auth}/>
            </TabsContainer>
            </div>
        </div>
)}
