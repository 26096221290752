import {useEffect, useMemo, useReducer, useRef, useState} from 'react'
import {
    Column,
    ColumnDef,
    ColumnFiltersState,
    FilterFn,
    SortingFn,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    sortingFns,
    useReactTable,
} from '@tanstack/react-table'

// A TanStack fork of Kent C. Dodds' match-sorter library that provides ranking information
import {
    RankingInfo,
    rankItem,
    compareItems,
} from '@tanstack/match-sorter-utils'



const IndeterminateCheckbox = ({indeterminate, className = '', ...rest}) => {
    const ref = useRef(null)

    useEffect(() => {
        if (typeof indeterminate === 'boolean') {
            ref.current.indeterminate = !rest.checked && indeterminate
        }
    }, [ref, indeterminate])

    return (
        <input
            type="checkbox"
            ref={ref}
            className={className + ' cursor-pointer'}
            {...rest}
        />
    )
}

// Define a custom fuzzy filter function that will apply ranking info to rows (using match-sorter utils)
const fuzzyFilter = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value)
    // Store the itemRank info
    addMeta({
        itemRank,
    })
    // Return if the item should be filtered in/out
    return itemRank.passed
}

// Define a custom fuzzy sort function that will sort by rank if the row has ranking information
const fuzzySort = (rowA, rowB, columnId) => {
    let dir = 0
    // Only sort by rank if the column has ranking information
    if (rowA.columnFiltersMeta[columnId]) {
        dir = compareItems(rowA.columnFiltersMeta[columnId]?.itemRank, rowB.columnFiltersMeta[columnId]?.itemRank)
    }
    // Provide an alphanumeric fallback for when the item ranks are equal
    return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir
}

export const Table = ({data, filtering, colNamingLambda, colVisibilityFilter, rowSelection ={}, setRowSelection}) => {
    const rerender = useReducer(() => ({}), {})[1]

    const [columnFilters, setColumnFilters] = useState([])
    const [globalFilter, setGlobalFilter] = useState('')
    const [columnVisibility, setColumnVisibility] = useState({_id: false})

    useEffect(() => {
        if (!data || data?.length === 0) return
        let excludeCols = colVisibilityFilter(Object.keys(data[0]))
        setColumnVisibility(excludeCols)
    }, [data])

    //example of col definition
    // const columns = useMemo(
    //     () => [
    //         {
    //             accessorKey: 'id',
    //             filterFn: 'equalsString', //note: normal non-fuzzy filter column - exact match required
    //         },
    //         {
    //             accessorKey: 'firstName',
    //             cell: info => info.getValue(),
    //             filterFn: 'includesStringSensitive', //note: normal non-fuzzy filter column
    //         },
    //         {
    //             accessorFn: row => row.lastName, //note: normal non-fuzzy filter column - case sensitive
    //             id: 'lastName',
    //             cell: info => info.getValue(),
    //             header: () => <span>Last Name</span>,
    //             filterFn: 'includesString', //note: normal non-fuzzy filter column - case insensitive
    //         },
    //         {
    //             accessorFn: row => `${row.firstName} ${row.lastName}`,
    //             id: 'fullName',
    //             header: 'Full Name',
    //             cell: info => info.getValue(),
    //             filterFn: 'fuzzy', //using our custom fuzzy filter function
    //             // filterFn: fuzzyFilter, //or just define with the function
    //             sortingFn: fuzzySort, //sort by fuzzy rank (falls back to alphanumeric)
    //         },
    //     ],
    //         []
    // )
    const mapHeader = () => {
        let selectionCol = [{
            id: 'select',
            header: ({ table }) => (
                <IndeterminateCheckbox
                    {...{
                        checked: table.getIsAllRowsSelected(),
                        indeterminate: table.getIsSomeRowsSelected(),
                        onChange: table.getToggleAllRowsSelectedHandler()
                    }}
                />
            ),
            cell: ({ row }) => (
                <div className="px-1">
                    <IndeterminateCheckbox
                        {...{
                            checked: row.getIsSelected(),
                            disabled: !row.getCanSelect(),
                            indeterminate: row.getIsSomeSelected(),
                            onChange: row.getToggleSelectedHandler()
                        }}
                    />
                </div>
            ),
        }]

        let headingMap = Object.keys(data[0]).map(heading =>  (
            {accessorKey: heading,
                cell:info => info.getValue(),
                header: colNamingLambda,
                filterFn:'includesStringSensitive'}
        ))
        if (!setRowSelection) return headingMap
        return selectionCol.concat(headingMap)
    }

    const columns = (!data || data?.length === 0) ?
        [{
            accessorKey: 'id',
            filterFn: 'equalsString', //note: normal non-fuzzy filter column - exact match required
        }] : mapHeader()

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter, //define as a filter function that can be used in column definitions
        },
        state: {
            columnFilters,
            globalFilter,
            columnVisibility,
            rowSelection
        },
        enableColumnFilters: filtering,
        enableRowSelection: !!setRowSelection,
        onRowSelectionChange: setRowSelection ? setRowSelection : null,
        onColumnVisibilityChange: setColumnVisibility,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: 'fuzzy', //apply fuzzy filter to the global filter (most common use case for fuzzy filter)
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(), //client side filtering
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })

    //apply the fuzzy sort if the fullName column is being filtered
    useEffect(() => {
        if (table.getState().columnFilters[0]?.id === 'fullName') {
            if (table.getState().sorting[0]?.id !== 'fullName') {
                table.setSorting([{ id: 'fullName', desc: false }])
            }
        }
    }, [table.getState().columnFilters[0]?.id])

    console.log('rendering TABLE')
    return (
        <div className="overflow-x-auto">
            <div className="h-2" />
            <table className="table table-xs table-pin-rows table-pin-cols border rounded">
                <thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => {
                            return (
                                <td key={header.id} colSpan={header.colSpan}>
                                    {header.isPlaceholder ? null : (
                                        <>
                                            <div
                                                {...{
                                                    className: header.column.getCanSort()
                                                        ? 'cursor-pointer select-none'
                                                        : '',
                                                    onClick: header.column.getToggleSortingHandler(),
                                                }}
                                            >
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                                {{
                                                    asc: ' 🔼',
                                                    desc: ' 🔽',
                                                }[header.column.getIsSorted().toString()] ?? null}
                                            </div>
                                            {header.column.getCanFilter() ? (
                                                <div>
                                                    <Filter column={header.column} />
                                                </div>
                                            ) : null}
                                        </>
                                    )}
                                </td>
                            )
                        })}
                    </tr>
                ))}
                </thead>
                <tbody>
                {table.getRowModel().rows.map(row => {
                    return (
                        <tr className="hover" key={row.id}>
                            {row.getVisibleCells().map(cell => {
                                return (
                                    <td key={cell.id}>
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                        )}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
            <div className="h-2" />
            <div className="flex items-center gap-2">
                <div>
                    <DebouncedInput
                        value={globalFilter ?? ''}
                        onChange={value => setGlobalFilter(String(value))}
                        className="input input-bordered input-sm w-full max-w-xs"
                        placeholder="Search all columns..."
                    />
                </div>
                <button
                    className="btn btn-sm"
                    onClick={() => table.setPageIndex(0)}
                    disabled={!table.getCanPreviousPage()}
                >
                    {'<<'}
                </button>
                <button
                    className="btn btn-sm"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                >
                    {'<'}
                </button>
                <button
                    className="btn btn-sm"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                >
                    {'>'}
                </button>
                <button
                    className="btn btn-sm"
                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                    disabled={!table.getCanNextPage()}
                >
                    {'>>'}
                </button>
            <span className="flex items-center gap-1">
            <div>Page</div>
            <strong>
                {table.getState().pagination.pageIndex + 1} of{' '}
                  {table.getPageCount()}
            </strong>
            </span>
        {/*<span className="flex items-center gap-1">*/}
        {/*  | Go to page:*/}
        {/*  <input*/}
        {/*      type="number"*/}
        {/*      defaultValue={table.getState().pagination.pageIndex + 1}*/}
        {/*      onChange={e => {*/}
        {/*          const page = e.target.value ? Number(e.target.value) - 1 : 0*/}
        {/*          table.setPageIndex(page)*/}
        {/*      }}*/}
        {/*      className="border p-1 rounded w-16"*/}
        {/*  />*/}
        {/*</span>*/}
            <select
                className="btn btn-xs"
                value={table.getState().pagination.pageSize}
                onChange={e => {
                    table.setPageSize(Number(e.target.value))
                }}
            >
                {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                    </option>
                ))}
            </select>
            { rowSelection ?
            <div>
                {Object.keys(rowSelection).length} of{' '}
                {table.getPreFilteredRowModel().rows.length} Total Rows Selected
            </div>
            : null}
        </div>
            {/*<div>{table.getPrePaginationRowModel().rows.length} Rows</div>*/}
            {/*<div>*/}
            {/*    <button onClick={() => rerender()}>Force Rerender</button>*/}
            {/*</div>*/}

            {/*<pre>*/}
        {/*{JSON.stringify(*/}
        {/*    {*/}
        {/*        columnFilters: table.getState().columnFilters,*/}
        {/*        globalFilter: table.getState().globalFilter,*/}
        {/*    },*/}
        {/*    null,*/}
        {/*    2*/}
        {/*)}*/}
      {/*</pre>*/}
        </div>
    )
}

const Filter = ({ column }) => {
    const columnFilterValue = column.getFilterValue()

    return (
        <DebouncedInput
            type="text"
            value={(columnFilterValue ?? '').toString()}
            onChange={value => column.setFilterValue(value)}
            placeholder={`Search...`}
            className="w-36 border shadow rounded"
        />
    )
}

// A typical debounced input react component
const DebouncedInput = ({
                            value: initialValue,
                            onChange,
                            debounce = 500,
                            ...props
                        }) => {
    const [value, setValue] = useState(initialValue)

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <input {...props} value={value} onChange={e => setValue(e.target.value)} />
    )
}
