import {useEffect, useState} from "react";
import {defaultReorderPeriods} from "../../../data/options";
import {mkConfig, generateCsv, download} from "export-to-csv";
import jsPDF from 'jspdf'
import {applyPlugin} from 'jspdf-autotable'
applyPlugin(jsPDF)


export const OrderExport = ({products, filters}) => {
    const [periods, setPeriods] = useState(defaultReorderPeriods)
    const [days, setDays] = useState(7)
    const csvConfig = mkConfig({ useKeysAsHeaders: true })

    useEffect(() => {
        const userInterval = (new Date(filters.end_date) - new Date(filters.start_date)) / (1000*3600*24)
        setPeriods([userInterval,...defaultReorderPeriods])
    }, [filters.start_date, filters.end_date])

    const reorderJson = () => {
        return products.map(
            x => { return {
                "Product Name": x._product_name,
                "SKU": x._sku,
                "Brand": x._brand_name,
                "Qty": ((x._burn_rate_period * days - x._qty).toFixed() > 0 ? parseInt((x._burn_rate_period * days - x._qty).toFixed()) : 0)
            }}
        ).filter( (x) => x.Qty > 0)
    }
    const reorderArray = () => {
        return products.map(
            x => { return [
                x._product_name, x._sku, x._brand_name,
                ((x._burn_rate_period * days - x._qty).toFixed() > 0 ? parseInt((x._burn_rate_period * days - x._qty).toFixed()) : 0)
            ]}
        ).filter( (x) => x[3] > 0)
    }

    const saveToCsv = () => {
        const data = reorderJson()
        if (!data || data?.length === 0) return
        const csv = generateCsv(csvConfig)(data)
        return download(csvConfig)(csv)
    }

    const saveToPdf = () => {
        let data = reorderArray()
        if (!data || data?.length === 0) return

        const head = [["Product Name", "SKU", "Brand", "Quantity"]]
        const headName = 'Reorder Invoice'
        const copyright = 'Generated with Stables App by Palomino Consulting'

        let pdf = new jsPDF({putOnlyUsedFonts: true});
        pdf.setFontSize(18)
        pdf.setTextColor(100)
        pdf.text(headName, 14, 25)
        pdf.autoTable({
            head: head,
            body: data,
            startY: 30,
            rowPageBreak: 'avoid',
            pageBreak: 'auto'})
        pdf.setFontSize(12)
        pdf.text(copyright, 14, pdf.lastAutoTable.finalY + 20)

        pdf.save('fileName.pdf')

    }

    const handeChange = event => {
        event.preventDefault()
        setDays(event.target.value)
    }

    return (
        <div className="flex flex-row-reverse items-center my-2">
            <select className="select btn-sm select-ghost w-full max-w-xs focus:outline-none" onChange={handeChange}>
                {periods.map(opt =>
                        <option value={opt} selected={opt === days ? true : null}>
                                        Reorder for the next {opt} days
                        </option>)}
            </select>
            <div className="dropdown">
                <div tabIndex={0} role="button" className="btn btn-sm w-sm max-w-xs">Export Order</div>
                <ul tabIndex={0} className="menu dropdown-content bg-base-100 rounded-box z-[2] w-52 p-2 mt-2 shadow">
                    <li><a onClick={saveToCsv}>Export as CSV</a></li>
                    <li><a onClick={saveToPdf}>Export as PDF</a></li>
                </ul>
            </div>
        </div>
    )
}